<template>
  <div class="rounded pa-4 bg-white mb-3">
    <div class="d-flex justify-space-between text-medium --c-prm flex-wrap">
      <div class="column text-left">
        <div>{{ row.createdAt | dateTime }}</div>
        <div>{{ row.changeReason }}</div>
      </div>
      <div class="column text-center">
        <div>{{ row.facilityName }}</div>
        <div v-if="row.checkInDate">（{{ row.checkInDate | jaShortDateSlash }} ~ {{ row.checkOutDate | jaShortDateSlash }}）</div>
      </div>
      <div class="column text-right">
        <div>
          <span class="text-huge">{{ row.pointChange | toThousands }}</span> ポイント
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VacationsHistoryItem',
  props: {
    row: Object
  }
}
</script>

<style scoped lang="scss">
.column {
  width: 200px;
  flex-grow: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}
</style>
