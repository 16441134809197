<template>
  <div class="member">
    <Breadcrumbs>
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class=" px-5 pb-13">
      <h2 class=" text-lg-center --dark px-4 pt-6 pb-5">
        {{ $route.meta.title }}
      </h2>
      <!-- START Member-card -->
      <div class="member-box mx-auto">
        <v-img
          src="@/assets/images/credit-card.png"
          max-width="355"
          max-height="232"
          class="mx-auto py-4 px-6"
        >
          <label class="--white">TOKYU VACATIONS Member</label>
          <div
            class="pos-absolute px-6 top-35 left-0 right-0 text-large--szm --white font-weight-light d-flex flex-column text-center"
          >
            {{ memberCard.name }}
            <span class="text-small pt-2">
              {{ $t("contractDetail.membershipNumber") }}
              {{ memberCard.memberId }}
            </span>
          </div>
        </v-img>
        <!-- END Member-card  -->

        <div class="form-container text-center px-7">
          <v-btn
            elevation="0"
            to="/forgot-password"
            x-large
            rounded
            color="btn-grad--prm"
            class="my-7 w-100"
          >
          <span class="text-heading font-weight-regular w-100">{{
                $t("menu.main.items.changePassword")
              }}</span>
            <IconChevronRightCircle
              color="white"
              width="23"
              height="23"
              class="mr-2"
            />
          </v-btn>
        </div>

        <!-- START Vacations-points -->
        <div
          v-if="this.$isMainUser() || this.$isSubUser()"
          class="rounded text-center pt-8 pb-7 px-5 bg-white"
        >
          <h2 class="--prm">
            {{ $t("memberInfo.vacationsPoints") }}
          </h2>
          <v-divider class=" my-2" />
          <div class="--prm font-weight-bold">
            <span class="text-x-heading">{{ totalVacationsPoints | toThousands }}</span>
            <span class="text-medium">
              {{
                Math.abs(totalVacationsPoints) > 1
                  ? $t("unit.points")
                  : $t("unit.point")
              }}
            </span>
          </div>
        </div>
        <!--  END Vacations-points -->
        <div
          v-if="this.$isMainUser()"
          class="list-bg rounded py-3"
        >
          <!-- START Vacations-points-history -->
          <h2 class="text-heading--prm mt-7 mb-3 text-center">
            {{ $t("memberInfo.vacationsPointsHistory") }}
          </h2>
          <VacationsHistoryItem
            v-for="item in items"
            :row="item"
            :key="item.id"
          />
          <div class="text-center">
            <v-btn
              elevation="0"
              @click="loadList"
              rounded
              outlined
              height="39"
              class="bg-white --prm px-7 "
            >
              {{ $t("reservationAndUsageHistory.seeMore") }}
            </v-btn>
          </div>
        </div>
        <!-- END Vacations-points-history -->

        <div class="rounded pa-5 mt-7 bg-w-silver">
          <!-- START Contact -->
          <div class="text-title --w-bold">
            {{ $t("common.contactAddress") }}
          </div>
          <v-divider class="mt-4 mb-5" />
          <div class="contact-box">
            <div class="pb-4">
              <span>{{ $t("common.telephone") }}</span>
              <span class="contact-float">{{ mainContact.tel || '未登録' }}</span>
            </div>

            <div class="pb-4">
              <span>{{ $t("common.mobile") }}</span>
              <span class="contact-float">{{ mainContact.mobile || '未登録' }}</span>
            </div>

            <div class="pb-4">
              <span>{{ $t("common.emailAddress") }}</span>
              <span class="contact-float">{{ mainContact.email || '未登録' }}</span>
            </div>

            <div>
              <span>{{ $t("common.sendingAddress") }}</span>
              <span class="contact-float text-medium">
                {{ $getAddressString(mainContact, {noPostalCode: true}) }}
              </span>
            </div>
            <br />
          </div>
          <div class="text-lg-right text-center">
            <v-btn
              v-if="$isMainUser()"
              elevation="0"
              to="/home/member-info/contact"
              x-large
              outlined
              rounded
              min-width="260"
              class="bg-white --prm mt-7"
            >
              {{ $t("memberInfo.changeContact") }}
            </v-btn>
          </div>

          <div class="text-lg-right text-center" v-if="$isMainUser()">
            <v-btn
              elevation="0"
              to="/home/member-info/mfa-policy"
              x-large
              outlined
              rounded
              min-width="260"
              class="bg-white --prm mt-7"
            >
              {{ $t("memberInfo.changeMFAPolicy") }}
            </v-btn>
          </div>
          <!-- END Contact -->
                  <!-- START Membership-type -->
        <template v-if="($isMainUser() || $isSubUser()) && !$isCompanyUser()"><!-- company user don't need to see this see TO2020-551 -->
            <div class="text-title --w-bold mt-15">
              {{ $t("memberInfo.membershipType") }}
            </div>
            <v-divider class="mt-4 mb-6" />
            <div class="text-lg-right text-center">
              <v-btn
                elevation="0"
                to="/home/member-info/member"
                x-large
                rounded
                outlined
                min-width="260"
                class="bg-white --prm"
                :disabled="!canModifySubMember"
              >
                {{ $t("memberInfo.changeMembersInfo") }}
              </v-btn>
              <div class="text-left mt-1" v-if="!canModifySubMember">
                ご変更が必要な方は<open-call-center />までご連絡ください
              </div>
            </div>
          </template>
          <!-- END Membership-type -->

          <!-- START About-payment -->
          <div class="text-title --w-bold mt-15">
            {{ $t("memberInfo.aboutPayment") }}
          </div>
          <v-divider class="mt-4 mb-6" />
          <div class="text-lg-right text-center">
            <v-btn
              elevation="0"
              v-if="this.$isCompanyUser()"
              to="/home/member-info/payment-method"
              x-large
              rounded
              outlined
              min-width="260"
              class="bg-white --prm"
            >
              {{ $t("memberInfo.confirmOrChangePaymentMethod") }}
            </v-btn>
          </div>
          <div class="text-lg-right text-center">
            <v-btn
              elevation="0"
              to="/home/member-info/receipt-list"
              x-large
              rounded
              outlined
              min-width="260"
              class="bg-white --prm mt-3"
            >
              {{ $t("buttons.issueAReceipt") }}
            </v-btn>
          </div>
          <!-- END About-payment -->

          <!-- START Credit-card -->
          <div class="text-title --w-bold mt-15">
            {{ $t("common.creditCard") }}
          </div>
          <v-divider class="mt-4 mb-6" />
          <div class="text-lg-right text-center mb-7">
            <v-btn
              elevation="0"
              to="/home/member-info/credit-card"
              x-large
              rounded
              outlined
              height="70"
              class="bg-white --w-bold --prm"
              :disabled="!$isMainUser() || !isCcAvailable"
            >
              <div class="text-pre-wrap mx-5 w-50">
                {{ $t("memberInfo.changeRegisteredCreditCard") }}
              </div>
            </v-btn>
            <div v-if="ccMaintenanceMessage" class="d-flex justify-end text-center --c-red">
              <format-span :value="ccMaintenanceMessage" />
            </div>
          </div>
          <!-- END Credit-card -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../../components/Breadcrumbs'
import VacationsHistoryItem from '../../components/VacationsHistoryItem'
import {
  paginationMixin
} from '@/mixins/pagination.mixin'
import IconChevronRightCircle from '../../components/icons/IconChevronRightCircle'
import OpenCallCenter from '@/components/Common/OpenCallCenter.vue'
import { creditCardMixin } from '@/mixins/credit-card.mixin'

export default {
  name: 'MemberInfo',
  mixins: [paginationMixin, creditCardMixin],
  components: {
    IconChevronRightCircle,
    Breadcrumbs,
    VacationsHistoryItem,
    OpenCallCenter
  },
  computed: {
    memberCard () {
      return this.$store.state.memberInfo.memberCard
    },
    mainContact () {
      if (this.$isFamilyUser()) {
        // this case we show the submember's address as the registered address.
        // see https://kujira.backlog.com/view/TO2020-551#comment-221222338 14
        return this.$store.state.user.userInfo ?? {}
      }
      return this.$store.state.memberInfo.mainContact ?? {}
    },
    pointsHistoryLists () {
      return this.$store.state.vacation.TvpItems
    },
    totalVacationsPoints () {
      return this.$store.getters.remainingTvp
    },
    canModifySubMember () {
      console.log(this.$store.state.memberInfo.canModifySubMember)
      return this.$store.state.memberInfo.canModifySubMember
    }
  },
  async mounted () {
    await this.loadList()
    await this.myClientInfo()
    await this.$store.dispatch('loadRemainingPoints')
  },
  methods: {
    async loadList () {
      await this.getPaginatedList((apollo, skip) => this.$store.dispatch('myTvpList', {
        apollo,
        skip,
        take: 3
      }))
    },
    async myClientInfo () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('myClientInfo', {
            apollo: this.$apollo
          })
          await this.$store.dispatch('loadUserInfo')
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@use '../../assets/styles/modules/variables-scss'as v;

.contact-box {
  width: 430px;
}
.list-bg{
  background: var(--color__white-silver);
}

.contact-float {
  float: right;
  margin-left: auto;
  margin-right: 0;
}

.member {
  &-box {
    max-width: 848px;
  }
}

@media (min-width: v.$breakpoint__lg) {
  .member {
    background: var(--color__white);
  }
}

@media (min-width: v.$breakpoint__small) and (max-width: v.$breakpoint__md) {
  .member {
    &-box {
      max-width: 738px;
    }
  }
}

@media (max-width: v.$breakpoint__small) and (min-width: v.$breakpoint__Extra-small) {
  .member {
    &-box {
      background: var(--color__silver);
    }
  }
  .list-bg{
    background: none;
  }
}

@media (max-width: v.$breakpoint__Extra-small) {
  .member {
  }
  .list-bg{
    background: none;
  }
}

  @media (max-width: v.$breakpoint__small) {
    .contact-box {
      width: 100%;
    }
    .contact-float {
      float: right;
    }
  }
  @media (min-width: v.$breakpoint__Extra-small) {
    .member {
      background: none;
    }
  }

</style>
