<template>
  <a href="#" @click.prevent="openCallCenter">
    {{ $t('booking.callCenter') }}
  </a>
</template>

<script>
export default {
  methods: {
    openCallCenter () {
      this.$store.commit('showCallCenterDialog')
    }
  }
}
</script>
