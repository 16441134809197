<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24">
    <path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'IconChevronRightCircle',
  props: {
    width: {
      type: String,
      default: '43.629'
    },
    height: {
      type: String,
      default: '43.641'
    },
    color: {
      type: String,
      default: '#e8ebef'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
